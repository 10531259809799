<template>
    <b-col>
      <i v-for="i in size" :key="`stepper-${i}`" class="material-icons bithan-color pr-3 pl-3">{{ (filled >= i) ? 'brightness_1' : 'panorama_fish_eye' }}</i>
    </b-col>
</template>

<script>
export default {
  props: {
    filled: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 5
    }
  }
}
</script>

<style> 

</style>